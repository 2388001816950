import {
    ApartmentRepository,
    BasipBackupRepository,
    CitizenAccessRepository,
    CitizenApplicationRepository,
    CitizenHouseRepository,
    CitizenRepository,
    CitizenTariffIconRepository,
    CitizenTariffRepository,
    HouseGroupRepository,
    IntercomForwardRepository
} from "citizens/repositories";
import {CitizenBalanceRepository} from "citizens/repositories/CitizenBalanceRepository";
import {IntercomRepository} from "citizens/repositories/IntercomRepository";
import {ServicesConfig} from "common/config";
import {AuthorizedHttpClient, HttpClient} from "common/net";
import {
    ColumnOrderRepository,
    DumpStateRepository,
    HiddenColumnRepository,
    ItemLimitRepository,
    SearchFieldRepository,
    SortOrderRepository,
    UploadsRepository
} from "common/repositories";
import {CommonPermissions} from "common/stores";
import {
    CollapsedCompanyRepository,
    CompanyBillingQrRepository,
    CompanyPassRepository,
    CompanyRepository,
    DelegationSettingRepository
} from "companies/repositories";
import {CompanyPermissions} from "companies/stores";
import {
    ClassifiedAddressRepository,
    GrantedHouseRepository,
    GrantedHouseTagRepository,
    HouseAccessRepository,
    HouseSystemRepository,
    HouseTagRepository,
    NewHouseRepository,
    TagColorRepository,
    VisibleSystemsRepository
} from "houses/repositories";
import React, {useContext} from "react";
import {ReportRepository} from "reports/repositories";
import {
    ChildTaskRepository,
    HouseEmployeeRepository,
    RelatedTaskRepository,
    SavedTaskFilterRepository,
    ScheduledTaskRepository,
    TaskColumnRepository,
    TaskDocumentRepository,
    TaskDocumentTypeRepository,
    TaskEventRepository,
    TaskProgramRepository,
    TaskRepository,
    TaskStatusRepository,
    TaskTypeRepository
} from "tasks/repositories";
import {DocumentTypeListStore, TaskPermissions} from "tasks/stores";
import {
    AuthRepository,
    DeviceIdRepository,
    MimicAuthRepository,
    NewUserRepository,
    UserRepository,
    UserRoleRepository
} from "users/repositories";
import {SessionStore} from "users/stores";

export class RepositoriesStore {
    readonly httpClient: AuthorizedHttpClient;

    readonly users: UserRepository;
    readonly companies: CompanyRepository;
    readonly companyBillingQr: CompanyBillingQrRepository;
    readonly companyPass: CompanyPassRepository;
    readonly houseSystems: HouseSystemRepository;
    readonly roles: UserRoleRepository;
    readonly taskType: TaskTypeRepository;
    readonly taskEvent: TaskEventRepository;
    readonly taskFilter: SavedTaskFilterRepository;
    readonly dumpState: DumpStateRepository;
    readonly searchField: SearchFieldRepository;
    readonly taskDocument: TaskDocumentRepository;
    readonly taskDocumentType: TaskDocumentTypeRepository;
    readonly taskDocumentTypeListStore: DocumentTypeListStore;
    readonly houseEmployee: HouseEmployeeRepository;
    readonly delegationSetting: DelegationSettingRepository;
    readonly task: TaskRepository;
    readonly taskStatuses: TaskStatusRepository;
    readonly order: SortOrderRepository;
    readonly relatedTask: RelatedTaskRepository;
    readonly taskProgram: TaskProgramRepository;
    readonly childTask: ChildTaskRepository;
    readonly scheduledTask: ScheduledTaskRepository;
    readonly auth: AuthRepository;
    readonly uploads: UploadsRepository;
    readonly taskColumns: TaskColumnRepository;
    readonly intercomRepo: IntercomRepository;
    readonly session: SessionStore;
    readonly mimicAuth: MimicAuthRepository;
    readonly address: ClassifiedAddressRepository;
    readonly houseTag: HouseTagRepository;
    readonly tagColor: TagColorRepository;
    readonly grantedHouseTag: GrantedHouseTagRepository;
    readonly houseAccess: HouseAccessRepository;
    readonly newHouse: NewHouseRepository;
    readonly grantedHouse: GrantedHouseRepository;
    readonly newUser: NewUserRepository;
    readonly columnOrder: ColumnOrderRepository;
    readonly hiddenColumns: HiddenColumnRepository;
    readonly visibleSystem: VisibleSystemsRepository;
    readonly report: ReportRepository;
    readonly citizen: CitizenRepository;
    readonly citizenAccess: CitizenAccessRepository;
    readonly citizenApplication: CitizenApplicationRepository;
    readonly apartment: ApartmentRepository;
    readonly intercomForward: IntercomForwardRepository;
    readonly balanceHistory: CitizenBalanceRepository;
    readonly houseGroups: HouseGroupRepository;
    readonly basipBackup: BasipBackupRepository;
    readonly citizenTariff: CitizenTariffRepository;
    readonly citizenTariffIcon: CitizenTariffIconRepository;
    readonly citizenHouse: CitizenHouseRepository;
    readonly servicesConfig: ServicesConfig;
    readonly commonPermissions: CommonPermissions;
    readonly taskPermissions: TaskPermissions;
    readonly companyPermissions: CompanyPermissions;
    readonly collapsedCompany: CollapsedCompanyRepository;
    readonly deviceRepo: DeviceIdRepository;
    readonly itemLimit: ItemLimitRepository;

    constructor() {
        this.servicesConfig = new ServicesConfig();
        const baseHttpClient = new HttpClient(this.servicesConfig.main);
        this.auth = new AuthRepository(baseHttpClient);
        this.deviceRepo = new DeviceIdRepository();
        this.session = new SessionStore(this.auth, this.deviceRepo);

        const citizenClient = new AuthorizedHttpClient(this.servicesConfig.citizen, this.auth, this.session, this.servicesConfig);
        const httpClient = new AuthorizedHttpClient(this.servicesConfig.main, this.auth, this.session, this.servicesConfig);
        this.httpClient = httpClient;

        this.users = new UserRepository(httpClient);
        this.companies = new CompanyRepository(httpClient);
        this.companyBillingQr = new CompanyBillingQrRepository(httpClient);
        this.companyPass = new CompanyPassRepository(httpClient);
        this.houseSystems = new HouseSystemRepository(httpClient);
        this.roles = new UserRoleRepository(httpClient);
        this.collapsedCompany = new CollapsedCompanyRepository();
        this.task = new TaskRepository(httpClient);
        this.order = new SortOrderRepository(httpClient);
        this.taskStatuses = new TaskStatusRepository(httpClient);
        this.searchField = new SearchFieldRepository(httpClient);
        this.taskDocument = new TaskDocumentRepository(httpClient);
        this.taskDocumentType = new TaskDocumentTypeRepository(httpClient);
        this.taskDocumentTypeListStore = new DocumentTypeListStore(this.taskDocumentType);
        this.relatedTask = new RelatedTaskRepository(httpClient);
        this.taskProgram = new TaskProgramRepository(httpClient);
        this.delegationSetting = new DelegationSettingRepository(httpClient)
        this.childTask = new ChildTaskRepository(httpClient);
        this.taskType = new TaskTypeRepository(httpClient);
        this.taskEvent = new TaskEventRepository(httpClient);
        this.scheduledTask = new ScheduledTaskRepository(httpClient);
        this.taskFilter = new SavedTaskFilterRepository(httpClient);
        this.dumpState = new DumpStateRepository(httpClient);
        this.houseEmployee = new HouseEmployeeRepository(httpClient)
        this.uploads = new UploadsRepository(httpClient);
        this.uploads = new UploadsRepository(httpClient);
        this.mimicAuth = new MimicAuthRepository(httpClient);
        this.address = new ClassifiedAddressRepository(httpClient)
        this.houseTag = new HouseTagRepository(httpClient);
        this.tagColor = new TagColorRepository(httpClient);
        this.grantedHouseTag = new GrantedHouseTagRepository(httpClient);
        this.houseAccess = new HouseAccessRepository(httpClient);
        this.newHouse = new NewHouseRepository(httpClient);
        this.grantedHouse = new GrantedHouseRepository(httpClient);
        this.newUser = new NewUserRepository(httpClient);
        this.taskColumns = new TaskColumnRepository();
        this.columnOrder = new ColumnOrderRepository();
        this.hiddenColumns = new HiddenColumnRepository();
        this.visibleSystem = new VisibleSystemsRepository();
        this.report = new ReportRepository(httpClient);
        this.citizen = new CitizenRepository(citizenClient);
        this.citizenAccess = new CitizenAccessRepository(citizenClient);
        this.citizenApplication = new CitizenApplicationRepository(citizenClient);
        this.apartment = new ApartmentRepository(citizenClient);
        this.intercomRepo = new IntercomRepository(citizenClient);
        this.citizenHouse = new CitizenHouseRepository(citizenClient);
        this.commonPermissions = new CommonPermissions();
        this.taskPermissions = new TaskPermissions();
        this.companyPermissions = new CompanyPermissions();
        this.balanceHistory = new CitizenBalanceRepository(citizenClient);
        this.basipBackup = new BasipBackupRepository(citizenClient);
        this.houseGroups = new HouseGroupRepository(citizenClient);
        this.intercomForward = new IntercomForwardRepository(citizenClient);
        this.citizenTariff = new CitizenTariffRepository(citizenClient);
        this.citizenTariffIcon = new CitizenTariffIconRepository(citizenClient);
        this.itemLimit = new ItemLimitRepository();
    }
}

// @ts-ignore
export const RepositoriesStoreContext = React.createContext<RepositoriesStore>(null);

export function useRepositories() {
    return useContext(RepositoriesStoreContext);
}
